import { Input } from "@mui/material";
import { Button } from "react-bootstrap";
// import { Unity, useUnityContext } from "react-unity-webgl";

export default function UnityFile() {
  // const { unityProvider, sendMessage, unload } = new useUnityContext({
  //   loaderUrl: "salsaBuild/salsa.loader.js",
  //   dataUrl: "salsaBuild/salsa.data",
  //   frameworkUrl: "salsaBuild/salsa.framework.js",
  //   codeUrl: "salsaBuild/salsa.wasm",
  // });
  //   const { unityProvider } = new useUnityContext({
  //     loaderUrl: "unityBuild/crateclicker.loader.js",
  //     dataUrl: "unityBuild/crateclicker.data",
  //     frameworkUrl: "unityBuild/crateclicker.framework.js",
  //     codeUrl: "unityBuild/crateclicker.wasm",
  //   });
  return (
    <div className="text-center">
      {/* <Unity
        unityProvider={unityProvider}
        style={{ width: "50%", height: "50%" }}
        // style={{ width: 400, height: 500 }}
      /> */}

      {/* <div className="mt-5 d-flex align-items-center justify-content-center gap-5">
        <Input placeholder="Enter"/>
        <Button
          onClick={() =>
            sendMessage("CharacterManager", "EnableMotionEventCall")
          }
        >
          Play
        </Button>
        <Button
          onClick={() =>
            sendMessage("CharacterManager", "DisableMotionEventCall")
          }
        >
          Stop
        </Button>
        <Button onClick={() => unload()}>Unload</Button>
        <Button onClick={() => sendMessage("Canvas", "CharacterSelected", 0)}>
          Male
        </Button>
        <Button onClick={() => sendMessage("Canvas", "CharacterSelected", 1)}>
          Female
        </Button>
        <Button onClick={() => sendMessage("Canvas", "LoadNextScene")}>
          Screen
        </Button>
      </div> */}
    </div>
  );
}
