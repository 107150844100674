import { getUserIdAPI } from "../api/user"

export function getUserId(){
  return(dispatch)=>{
    dispatch({
      type: 'GET_USER_ID_REQUEST'
    })
    getUserIdAPI().then(
      (response)=>{
        dispatch({
          type: 'GET_USER_ID_SUCCESS', 
          payload: response.data,
        })
      },(error)=>{
        dispatch({
          type: "GET_USER_ID_FAILURE",
          payload: error,
        })
      }
    )
  }
}


