import { IMAGES } from "../../Assets";
import { Box, Typography } from "../../Components";

export default function ThankYou() {
  return (
    <Box
      width='100%'
      maxWidth='202rem'
      minHeight='50rem'
      borderRadius='1.375rem'
      background='linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)'
      shadow='0px 0px 40px 20px rgba(0, 0, 0, 0.09)'
      backdropFilter='blur(15px)'
      className='d-flex py-5 flex-column justify-content-center align-items-center'
    >
      <img
        src={IMAGES.THANKYOU_ICON}
        alt=""
        width={500}
      />
      <Typography
        fontSize='6rem'
      >
        Thank you for time!
      </Typography>
      <Typography
        fontSiz='4rem'
      >
        The doctor will reach out you soon.
      </Typography>
    </Box>
  )
}
