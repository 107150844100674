import { Route, Routes } from "react-router-dom";

import { ROUTER_PATH } from "../../config";
import PrivateRoutes from "./PrivateRoutes";
import Layout from "../../Layout";
import { SignIn } from "../../Pages/Auth";
import Home from "../../Pages/Home";
import PatientDiagnosis from "../../Pages/PatientDiagnosis";
import ThankYou from "../../Pages/ThankYou";
import VitalsMonitoring from "../../Pages/VitalsMonitoring";
import ChatCard from "../../Pages/StartChat/StartChatCard";
import UnityFile from "../../Pages/Auth/unity";

export default function AllRoutes() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path={ROUTER_PATH.SIGN_IN} element={<SignIn />} />
      <Route path={"/unity"} element={<UnityFile />} />
      <Route element={<Layout />}>
        {/* Private Routes */}
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTER_PATH.HOME_PAGE} element={<Home />} />
          <Route
            path={ROUTER_PATH.VITALS_MONITORING}
            element={<VitalsMonitoring />}
          />
          <Route path={ROUTER_PATH.START_CHAT} element={<ChatCard />} />
          <Route
            path={ROUTER_PATH.PATIENT_DIAGNOSIS}
            element={<PatientDiagnosis />}
          />
          <Route path={ROUTER_PATH.THANK_YOU} element={<ThankYou />} />
        </Route>
      </Route>
    </Routes>
  );
}
