import React, { useEffect, useState } from "react";
import { Box, Button, GenericLoader, Typography } from "../../Components";
import { IMAGES } from "../../Assets";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { ROUTER_PATH } from "../../config";
import { getUserId } from "../../Redux/actions";
import { Col, Row } from "react-bootstrap";

export default function ChatCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isGender, setIsGender] = useState(null);
  const { state } = useLocation();

  const userId = useSelector((state) => state.users);

  // console.log("state", state);

  const handleGetUserId = () => {
    dispatch(getUserId());
  };
  useEffect(() => {
    localStorage.setItem("userId", userId?.USER_ID);

    userId?.USER_ID &&
      isGender &&
      navigate(ROUTER_PATH.PATIENT_DIAGNOSIS, {
        state: { isGender, vitals: state?.vitals },
      });
  }, [userId]);

  return (
    <div className="container">
      <Row>
        <Col
          lg={5}
          md={6}
          width="100%"
          maxWidth="35rem"
          borderRadius="1.375rem"
          boxShadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09);"
          className="mx-auto my-5"
        >
          <Box
            width="100%"
            background="#ffffff"
            className="d-flex justify-content-center"
          >
            <img
              src={IMAGES.FEMALE_CHARACTER}
              alt="female"
              className="img-fluid vh-50"
            />
          </Box>

          <Box
            width="100%"
            background="var(--grad, linear-gradient(113deg, #53972C 0%, #ACDD59 100%))"
            className="modal-body-content p-5"
          >
            <Typography color="#ffffff" as="h1" align="center">
              Hello!
            </Typography>

            <Typography color="#ffffff" as="h3" align="center">
              I’m Doctor Shah
            </Typography>

            <Typography color="#ffffff" as="p" align="center" className="my-3">
              Your digital doctor.I will help you to diagnose your problem by
              ask step by step questions in chat.
            </Typography>

            <div className="d-flex justify-content-center">
              <Button
                width="15rem"
                onClick={() => {
                  setIsGender("female");
                  handleGetUserId();
                }}
              >
                {/* {userId?.GET_USER_ID_LOADING ? (
                  <GenericLoader animation="border" />
                ) : ( */}
                <Typography fontSize="1.rem" fontWeight="700" align="center">
                  Let's Start Consultation
                </Typography>
                {/* )} */}
              </Button>
            </div>
          </Box>
        </Col>

        <Col
          lg={5}
          md={6}
          width="100%"
          maxWidth="35rem"
          borderRadius="1.375rem"
          boxShadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09);"
          className="mx-auto my-5"
        >
          <Box
            width="100%"
            background="#ffffff"
            className="d-flex justify-content-center"
          >
            <img
              src={IMAGES.MALE_CHARACTER}
              alt="male"
              className="img-fluid vh-50"
            />
          </Box>

          <Box
            width="100%"
            background="var(--grad, linear-gradient(113deg, #53972C 0%, #ACDD59 100%))"
            className="modal-body-content p-5"
          >
            <Typography color="#ffffff" as="h1" align="center">
              Hello!
            </Typography>

            <Typography color="#ffffff" as="h3" align="center">
              I’m Doctor William
            </Typography>

            <Typography color="#ffffff" as="p" align="center" className="my-3">
              Your digital doctor.I will help you to diagnose your problem by
              ask step by step questions in chat.
            </Typography>

            <div className="d-flex justify-content-center">
              <Button
                width="15rem"
                onClick={() => {
                  setIsGender("male");
                  handleGetUserId();
                }}
              >
                {/* {userId?.GET_USER_ID_LOADING ? (
                  <GenericLoader animation="border" />
                ) : ( */}
                <Typography fontSize="1.rem" fontWeight="700" align="center">
                  Let's Start Consultation
                </Typography>
                {/* )} */}
              </Button>
            </div>
          </Box>
        </Col>
      </Row>
    </div>
  );
}
