const INITIAL_STATES = {
  GET_WELCOME_MESSAGE_IS_LOADING: false,
  GET_WELCOME_MESSAGE_IS_SUCCESS: false,
  GET_WELCOME_MESSAGE_IS_FALURE: false,
  WELCOME_MESSAGE: null,
  GET_WELCOME_MESSAGE_IS_ERROR: null,

  GET_PROMPT_RESPONSE_IS_LOADING: false,
  GET_PROMPT_RESPONSE_IS_SUCCESS: false,
  GET_PROMPT_RESPONSE_IS_FAILURE: false,
  PROMPT_RESPONSE: [],
  GET_PROMPT_RESPONSE_IS_ERROR:null,

  SAVE_CHAT_HISTORY_IS_LOADING: false,
  SAVE_CHAT_HISTORY_IS_SUCCESS: false,
  SAVE_CHAT_HISTORY_IS_FAILURE: false,
  SAVE_CHAT_HISTORY_IS_ERROR: null,

  GET_CHAT_HISTORY_IS_LOADING: false,
  GET_CHAT_HISTORY_IS_SUCCESS: false,
  GET_CHAT_HISTORY_IS_FAILUER: false,
  CHAT_HISTORY: [],
  GET_CHAT_HISTORY_IS_ERROR: null,
}

export function chatReducer(state=INITIAL_STATES, action){
  switch (action.type) {
    case "GET_WELCOME_MESSAGE_REQUEST":
      return{
        ...state,
        GET_WELCOME_MESSAGE_IS_LOADING: true,
        GET_WELCOME_MESSAGE_IS_SUCCESS:false,
        GET_WELCOME_MESSAGE_IS_FALURE: false,
        WELCOME_MESSAGE: null,
        GET_WELCOME_MESSAGE_IS_ERROR: null,
      }
    case "GET_WELCOME_MESSAGE_SUCCESS":
      return{
        ...state,
        GET_WELCOME_MESSAGE_IS_LOADING : false ,
        GET_WELCOME_MESSAGE_IS_SUCCESS  :true,
        GET_WELCOME_MESSAGE_IS_FALURE   : false,
        WELCOME_MESSAGE: action.payload,
        GET_WELCOME_MESSAGE_IS_ERROR: null,
      }
    case "GET_WELCOME_MESSAGE_FAILURE":
      return{
        ...state,
        GET_WELCOME_MESSAGE_IS_LOADING: false,
        GET_WELCOME_MESSAGE_IS_SUCCESS: false,
        GET_WELCOME_MESSAGE_IS_FALURE: true,
        WELCOME_MESSAGE: null,
        GET_WELCOME_MESSAGE_IS_ERROR: action.payload,
      }

    case "GET_PROMPT_RESPONSE_REQUEST":
      return{
        ...state,
        GET_PROMPT_RESPONSE_IS_LOADING: true,
        GET_PROMPT_RESPONSE_IS_SUCCESS: false,
        GET_PROMPT_RESPONSE_IS_FAILURE: false,
        PROMPT_RESPONSE:null,
        GET_PROMPT_RESPONSE_IS_ERROR: null
      }
    case "GET_PROMPT_RESPONSE_SUCCESS":
      return{
        ...state,
        GET_PROMPT_RESPONSE_IS_LOADING: false,
        GET_PROMPT_RESPONSE_IS_SUCCESS: true,
        GET_PROMPT_RESPONSE_IS_FAILURE: false,
        PROMPT_RESPONSE: action.payload,
        GET_PROMPT_RESPONSE_IS_ERROR: null,
      }
    case "GET_PROMPT_RESPONSE_ERROR":
      return{
        ...state,
        GET_PROMPT_RESPONSE_IS_LOADING: false,
        GET_PROMPT_RESPONSE_IS_SUCCESS: false,
        GET_PROMPT_RESPONSE_IS_FAILURE: true,
        PROMPT_RESPONSE: null,
        GET_PROMPT_RESPONSE_IS_ERROR:action.payload,
      }


      case "GET_AUDIO_FROM_TEXT_REQUEST":
        return{
          ...state,
          GET_AUDIO_FROM_TEXT_IS_LOADING: true,
          GET_AUDIO_FROM_TEXT_IS_SUCCESS: false,
          GET_AUDIO_FROM_TEXT_IS_FAILURE: false,
          AUDIO_FROM_TEXT:null,
          GET_AUDIO_FROM_TEXT_IS_ERROR: null
        }
      case "GET_AUDIO_FROM_TEXT_SUCCESS":
        return{
          ...state,
          GET_AUDIO_FROM_TEXT_IS_LOADING: false,
          GET_AUDIO_FROM_TEXT_IS_SUCCESS: true,
          GET_AUDIO_FROM_TEXT_IS_FAILURE: false,
          AUDIO_FROM_TEXT: action.payload,
          GET_AUDIO_FROM_TEXT_IS_ERROR: null,
        }
      case "GET_AUDIO_FROM_TEXT_ERROR":
        return{
          ...state,
          GET_AUDIO_FROM_TEXT_IS_LOADING: false,
          GET_AUDIO_FROM_TEXT_IS_SUCCESS: false,
          GET_AUDIO_FROM_TEXT_IS_FAILURE: true,
          AUDIO_FROM_TEXT: null,
          GET_AUDIO_FROM_TEXT_IS_ERROR:action.payload,
        }
  

    case "SAVE_CHAT_HISTORY_REQUEST":
      return{
        ...state,
        SAVE_CHAT_HISTORY_IS_LOADING: true,
        SAVE_CHAT_HISTORY_IS_SUCCESS: false,
        SAVE_CHAT_HISTORY_IS_FAILURE: false,
        SAVE_CHAT_HISTORY_IS_ERROR: null,
      }
    case "SAVE_CHAT_HISTORY_SUCCESS":
      return{
        ...state,
        SAVE_CHAT_HISTORY_IS_LOADING: false,
        SAVE_CHAT_HISTORY_IS_SUCCESS: true,
        SAVE_CHAT_HISTORY_IS_FAILURE: false,
        SAVE_CHAT_HISTORY_IS_ERROR: null,
      }
    case "SAVE_CHAT_HISTORY_FAILURE":
      return{
        ...state,
        SAVE_CHAT_HISTORY_IS_LODAING: false,
        SAVE_CHAT_HISTORY_IS_SUCCESS: false,
        SAVE_CHAT_HISTORY_IS_FAILURE: true,
        SAVE_CHAT_HISTORY_IS_ERROR: action.payload,
      }

    case "GET_CHAT_HISTORY_REQUEST":
      return{
        ...state,
        GET_CHAT_HISTORY_IS_LOADING: true,
        GET_CHAT_HISTORY_IS_SUCCESS:false,
        GET_CHAT_HISTORY_IS_FAILURE: false,
        CHAT_HISTORY: [],
        GET_CHAT_HISTORY_IS_ERROR: null,
      }
    case "GET_CHAT_HISTORY_SUCCESS":
      return{
        ...state,
        GET_CHAT_HISTORY_IS_LOADING: false,
        GET_CHAT_HISTORY_IS_SUCCESS :true ,
        GET_CHAT_HISTORY_IS_FAILURE: false,
        CHAT_HISTORY: action.payload,
        GET_CHAT_HISTORY_IS_ERROR: null,
      }
    case "GET_CHAT_HISTORY_IS_FAILURE":
      return{
        ...state,
        GET_CHAT_HISTORY_IS_LOADING: false,
        GET_CHAT_HISTORY_IS_SUCCESS: false,
        GET_CHAT_HISTORY_IS_FAILURE: true,
        CHAT_HISTORY: [],
        GET_CHAT_HISTORY_IS_ERROR: action.payload,
      }

    default:
      return state;
  }
}