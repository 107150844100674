import { 
  BrowserRouter, 
  Route, 
  Routes 
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-dots-loader/index.css'
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import AllRoutes from './Navigation/Routes/Routes';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<AllRoutes/>} />
      </Routes>
      <ToastContainer/>
    </BrowserRouter>
  );
}

export default App;
