import { useFormik } from "formik";
import { ROUTER_PATH } from "../../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { GenericInput, InputField, Toaster } from "../../../Components";
import * as Yup from "yup";
import { IMAGES } from "../../../Assets";

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const SIGN_IN_SCHEMA = Yup.object().shape({
    email: Yup.string().required("Please enter your email address"),
    password: Yup.string().required("Please enter your password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SIGN_IN_SCHEMA,
    onSubmit: (values) => {
      if (
        values.email === "patient@innotech.com" &&
        values.password === "patient@123"
      ) {
        localStorage.setItem("innotech_patient", JSON.stringify(values));
        navigate(ROUTER_PATH.HOME_PAGE);
      } else {
        Toaster({ message: "Email/password is wrong!", type: "error" });
      }
    },
  });
  return (
    <div
      className={
        location.pathname === ROUTER_PATH.SIGN_IN
          ? "bg-white"
          : "main-container"
      }
    >
      <Row className="m-0 p-0">
        <Col sm={12} lg={{ span: 4, offset: 4 }}>
          <div className="w-100 mx-auto vh-100 d-flex justify-content-center align-items-center flex-column">
            <div className="my-3 d-flex justify-content-center">
              <img
                alt="test"
                src={IMAGES.SIDEBAR_LOGO}
                className="my-4"
              />
            </div>
            <div className="bg-white shadow">
              <form
                onSubmit={formik.handleSubmit}
                className="container-fluid rounded-1"
              >
                <Row className="m-0 p-4">
                  <h3 className="text-center mb-4">Login</h3>
                  <Col md={12}>
                    <h5 className="mb-0 mt-3">Email Address</h5>
                    <InputField
                      type="email"
                      name="email"
                      placeholder="Type your email"
                      className="w-100 mb-2"
                      onChange={formik.handleChange}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <small className="text-danger small">
                        {formik.errors.email}
                      </small>
                    )}
                  </Col>
                  <Col md={12}>
                    <h5 className="mb-0 mt-3">Password</h5>
                    <InputField
                      type="password"
                      name="password"
                      placeholder="Type your password"
                      className="w-100 mb-2"
                      onChange={formik.handleChange}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <small className="text-danger small">
                        {formik.errors.password}
                      </small>
                    )}
                  </Col>
                  <Col md={12}>
                    <button
                      type="submit"
                      className="w-100 my-2 h5 text-white"
                      style={{
                        background: "#53972C",
                        border: "none",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      {/* <h6 className="text-white">Save</h6> */}
                      Login
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
