import axios from "axios";
import { API_URLS } from "../../config";

function getUserIdAPI(){
  return axios.get(
    `${API_URLS.USER.GENERATE_ID}`,
  )
}

export {
  getUserIdAPI,
}