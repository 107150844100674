import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";

export default function PrivateRoutes() {
  const auth = localStorage.getItem("innotech_patient") ? true : false;
  
  const location = useLocation();
  return auth ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTER_PATH.SIGN_IN} state={{ from: location }} replace />
  );
}
