const INITIAL_STATES = {
  GET_USER_ID_LOADING: false,
  GET_USER_ID_IS_SUCCESS: false,
  GET_USER_ID_IS_FAILURE: false,
  GET_USER_ID_DATA: [],
  GET_USER_ID_IS_ERROR: null,
  USER_ID: null
}

export function userReducer(state=INITIAL_STATES, actions){
  switch (actions.type) {
    case 'GET_USER_ID_REQUEST':
      return{
        ...state,
        GET_USER_ID_LOADING : true ,
        GET_USER_ID_IS_SUCCESS  :false,
        GET_USER_ID_IS_FAILURE   :false,
        GET_USER_ID_DATA: null,
        GET_USER_ID_IS_ERROR: null,
        USER_ID: null,
      }
    case 'GET_USER_ID_SUCCESS':
      return{
        ...state,
        GET_USER_ID_LOADING: false,
        GET_USER_ID_IS_SUCCESS: true,
        GET_USER_ID_IS_FAILURE: false,
        GET_USER_ID_DATA: actions.payload,
        USER_ID: actions.payload.user_id,
        GET_USER_ID_IS_ERROR: null,
      }
    case 'GET_USER_ID_FAILURE':
      return{
        ...state,
        GET_USER_ID_LOADING: false,
        GET_USER_ID_IS_SUCCESS:false ,
        GET_USER_ID_IS_FAILURE :true,
        GET_USER_ID_DATA:null,
        GET_USER_ID_IS_ERROR: actions.payload.message,
        USER_ID: null,
      }
  
    default:
      return state;
  }
}