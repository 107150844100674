import { Container, Navbar } from 'react-bootstrap';
import { IMAGES } from '../../Assets';

export default function LayoutNavbar() {
  return (
    <Navbar className="bg-none">
      <Container className='m-0'>
        <Navbar.Brand href="#home">
          <img
            src={IMAGES.INNOTECH_LOGO}
            width='250px'
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  )
}
