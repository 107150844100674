import PropTypes from 'prop-types'

export default function Button({
  children,
  ...props
}) {
  const buttonStyles = {
    width: props.width,
    height: props.height,
    backgroundColor: props.backgroundColor,
    border: props.border,
    borderRadius: props.borderRadius
  }
  return (
    <button {...props} style={buttonStyles} className={` ${props.className}`} >
      {children}
    </button>
  )
}

Button.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
  isIconLeft: PropTypes.bool,
  isIconRight: PropTypes.bool,
  className: PropTypes.string
}

Button.defaultProps = {
  width:'10rem',
  height:"3rem",
  backgroundColor : "#FABA31" ,
  border: 'none',
  borderRadius: '14px',
  onClick: ()=>{},
  hasIcon: false,
  isIconLeft: false,
  isIconRight: false,
  className: '',
}